.navbar-search .search_text, .navbar-search .search_text::placeholder{
    color: #525f7f;
}
.navbar-search .search-tables {
    border-color: rgb(17, 169, 239);
}
.navbar-search .select_search{
    font-size: 0.65rem;
}

.table thead th{
    text-align: center;
    max-width: 100px;
    min-width: 50px;
    word-wrap: break-word;
    white-space: normal;
    font-size: 0.55rem;
    
}
.table thead .images {
    max-width: 200px;
    min-width: 160px;
}
.card .table td, .card .table th {
    padding-left: 0.2rem;
    padding-right: 0.2rem;
    word-wrap: break-word;
    white-space: normal;
    text-align: center;
}
.table td {
    font-size: 0.7rem;
}
.table .images_products{
    max-width: 180px;
}

.avatar-group{
    width: 60px;
    margin: 0;
    .avatar {
        margin-right: 0;
        border-radius: 0%;
        width: 60px;
        height: 60px;
        background-color: white;
        img{
            border-radius: 0%;
            max-height: 60px;
        }
    }
}

.exhibitorImage{
    border: 1px solid #e6e6e6;
    max-height: 140px;
    width: 30%;
}
.modal-header{
    h5 {
        margin-bottom: 0;
    }
}

.modal-body tbody td{
    white-space: normal;
}